@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");
html,
body {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.visually-hidden {
  height: 1px;
  overflow: hidden;
  /* position: a  bsolute; */
  width: 1px;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  white-space: nowrap;
}

.nav-link {
  position: relative;
}

.nav-link::before {
  content: "";
  position: absolute;
  top: 39px;
  height: 7px;
  width: 20px;
  border-radius: 8px;
  background-color: #ffd700;
  margin: auto;
  text-align: center;
  left: 27%;
}

.box-shadow-sm {
  box-shadow: 0px 2px 19px #71717138;
}

.box-shadow {
  box-shadow: 0px 50px 80px #82828233;
}

.accordion-closed {
  max-height: 0px;
  transition: all 400ms ease-out;
}

.accordion-open {
  max-height: 100vh;
  transition: all 400ms ease-out;
}

.signup-animation {
  animation: signupAnimation 700ms ease-out;
}

.scale-on-hover {
  transform-origin: 50% 0%;
  transition: transform 500ms ease-out;
}

.scale-on-hover:hover {
  transform: scale(1.2);
  transition: transform 500ms ease-out;
}

.background-filter {
  filter: brightness(76%);
}

.box-shadow-hover {
  transition: box-shadow 400ms ease-out;
}

.box-shadow-hover:hover {
  box-shadow: 0px 50px 80px #82828233;
}

.slides {
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
}

.slides::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.slides::-webkit-scrollbar-thumb {
  background: #03398e;
  border-radius: 8px;
}

.slides::-webkit-scrollbar-track {
  background: transparent;
}

@media screen and (min-width: 720px) {
  .slides::-webkit-scrollbar-track {
    background: transparent;
    margin-left: 240px;
    margin-right: 240px;
  }
}

@media screen and (min-width: 1024px) {
  .slides::-webkit-scrollbar-track {
    background: transparent;
    margin-left: 280px;
    margin-right: 280px;
  }
}

.img-line::before {
  position: absolute;
  margin-top: 46px;
  margin-left: 22px;
  content: "";
  height: 2px;
  width: 280px;
  background-color: #03398e;
}

.auth-error-animation {
  animation: authError 300ms ease-out;
}

.close-modal {
  transform: translateY(-400px);
  opacity: 0;
  transition: opacity 300ms ease-out;
}

.open-modal {
  transform: none;
  opacity: 1;
  transition: opacity 300ms ease-out;
}

.dashboard-box:hover p {
  color: #808181;
}
/* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.8s ease-out;
}
.ripple:hover {
  background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
}
.ripple:active {
  background-color: #6eb9f7;
  background-size: 100%;
  transition: background 0s ease-out;
}

@keyframes authError {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes signupAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* @media screen and (min-width: 1024px) {
  .img-line::before {
    position: relative;
    content: "";
    left: 148px;
    top: 24%;
    width: calc(36px + 70%);
    height: 2px;
    background-color: #03398E;
  }
}

@media screen and (min-width: 1280px) {
  .img-line::before {
    position: relative;
    content: "";
    left: 150px;
    top: 24%;
    width: calc(36px + 100%);
    height: 2px;
    background-color: #03398E;
  }
} */

/* tailwind css */
@tailwind base;
@tailwind components;
@tailwind utilities;
